import {
  QueryClient,
  QueryCache,
  useQueryClient as rqUseQueryClient,
  useQuery as rqUseQuery,
  useInfiniteQuery as rqUseInfiniteQuery,
  useMutation as rqUseMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query";
import { handleError } from "./error";
import { setupHandleError } from "error-logging!sofe";

const queryCache = new QueryCache({
  onError: (err, query) => {
    const baseKey = query.queryKey[0];
    if (baseKey && typeof baseKey === "object" && "service" in baseKey && typeof baseKey.service === "string") {
      const handleServiceError = setupHandleError(baseKey.service);
      handleServiceError(err);
    } else {
      handleError(err);
    }
  },
});

export const queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export function useQueryClient() {
  return rqUseQueryClient(queryClient);
}

export function useQuery<TData = unknown, TError = unknown, TQueryFnData = TData>(
  queryOptions: UseQueryOptions<TData, TError, TQueryFnData>
) {
  return rqUseQuery<TData, TError, TQueryFnData>(queryOptions, queryClient);
}

export function useInfiniteQuery<TData = unknown, TError = unknown, TQueryFnData = TData>(
  queryOptions: UseInfiniteQueryOptions<TData, TError, TQueryFnData>
) {
  return rqUseInfiniteQuery<TData, TError, TQueryFnData>(queryOptions, queryClient);
}

export function useMutation<TData = unknown, TError = Error, TVariables = void, TContext = unknown>(
  mutationOptions: UseMutationOptions<TData, TError, TVariables, TContext>
) {
  return rqUseMutation<TData, TError, TVariables, TContext>(mutationOptions, queryClient);
}

type BaseQueryKeyObj = { service: string; entity: string };

export function setupQueryKeygen(serviceName: string) {
  /**
   * Creates the base key to be used for every query.
   * @param entityName - The name of the entity. This name will be used to invalidate all queries that share this entity name.
   */
  const genBaseKey = (entityName: string): [BaseQueryKeyObj] => {
    return [
      {
        service: serviceName,
        entity: entityName,
      },
    ];
  };

  /**
   * Generates a 'standalone' query key to be used for any one off queries.
   *
   * @param entityName - The name of the query.
   * @param queryParams - The parameters for the query. This should contain all the parameters that are used in your queryFn.
   * @returns The generated query key. First item being the base key, the second being entity name, and the third item being the query parameters.
   */
  const genQueryKey = <TQueryParams>(
    entityName: string,
    queryParams: TQueryParams = {} as TQueryParams
  ): [BaseQueryKeyObj, string, TQueryParams] => {
    return [genBaseKey(entityName)[0], entityName, queryParams];
  };

  /**
   * Invalidates the queries associated with the specified entity.
   * @param entityName - The name of the entity.
   */
  const invalidateEntity = (entityName: string) => {
    return queryClient.invalidateQueries({ queryKey: [{ entity: entityName }] });
  };

  /**
   * Generates an object from the one provided in the callback, and adds a utility method to generate query keys as well as providing a default invalidation method.
   *
   * @param entityName - The name of the entity, this will be used to group all queries that share the same resource. e.g. contacts, clients, tasks would be entity names.
   * @param getQueriesObject - A function that should return an object with your queryOptions or anything else you want to return. The callback will provide a `genKey` method for generating the keys.
   * @returns Whatever you return from the `getQueriesObject` callback, with the addition of an `invalidate` method that will invalidate all queries that share the same entity name.
   */
  const genQueries = <TQueriesObject>(
    entityName: string,
    getQueriesObject: (params: {
      genKey: <TQueryParams>(queryName: string, queryParams?: TQueryParams) => [BaseQueryKeyObj, string, TQueryParams];
    }) => TQueriesObject
  ) => {
    const baseKey = genBaseKey(entityName);

    /**
     * Generates a composite query key that includes the base key, query name, and query parameters.
     * @param queryName - A unique name for this query. Only needs to be unique per entity.
     * @param queryParams - The parameters for the query. This should contain all the parameters that are used in your queryFn.
     * @returns A query key [baseKey, queryName, queryParams]
     */
    const genKey = <TQueryParams>(
      queryName: string,
      queryParams: TQueryParams = {} as TQueryParams
    ): [BaseQueryKeyObj, string, TQueryParams] => {
      return [baseKey[0], queryName, queryParams];
    };

    return {
      baseKey,
      invalidate: () => invalidateEntity(entityName),
      ...getQueriesObject({ genKey }),
    };
  };

  return {
    genBaseKey,
    genQueryKey,
    genQueries,
    invalidateEntity,
  };
}

export const { genBaseKey, genQueryKey, genQueries } = setupQueryKeygen("global-settings");
