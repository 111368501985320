import React from "react";
import SettingsMenu from "./settings-menu.component.js";
import TopnavSecondary from "./settings-breadcrumbs.js";
import { shouldHideLeftMenu } from "./settings.helper.js";
import { ErrorBoundary } from "error-logging!sofe";
import Disposable from "react-disposable-decorator";

import Pages from "./pages/pages.component.js";

import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "src/react-query";

@ErrorBoundary({ featureName: "settings", noStrictMode: false })
@Disposable
export default class Root extends React.Component {
  constructor() {
    super();
    this._forceUpdate = () => this.forceUpdate();
  }

  componentDidMount() {
    window.addEventListener("hashchange", this._forceUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this._forceUpdate);
  }

  render() {
    const { hash } = window.location;

    const navContentStyle = {
      marginLeft: "318px",
      width: "calc(100% - 318px)",
      padding: "24px 16px",
      height: "calc(100vh - 56px)",
    };
    return (
      <QueryClientProvider client={queryClient}>
        <div>
          <TopnavSecondary />
          {!shouldHideLeftMenu() ? <SettingsMenu /> : null}
          {hash.indexOf("#/global-settings") === 0 ? <Pages navContentStyle={navContentStyle} /> : null}
        </div>
      </QueryClientProvider>
    );
  }
}
